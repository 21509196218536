import { useEffect, useState } from 'react';
import { AnimatePage } from 'AnimatePage';
import { Container } from 'Container';
import { SeoHead } from 'SeoHead';
import { PlaceholderIndex } from 'PlaceholderIndex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import {
	IPage,
	IRequestData,
	ISpreadFilter,
	ISpreadIndex,
	ShowType,
} from '@types';
import Select from 'react-select';
import { ShowTypeItem } from 'ShowTypeItem';
import { VersusList } from 'VersusList';
import { mapCountryOptions } from 'utils/mappings';
import {
	dataTypes,
	NEXT_PUBLIC_API_URL,
	saveFilterCookie,
	theCountries,
	useFetchCompare,
	useFetchIndex,
} from 'utils/core';
import { NextPage } from 'next';
import { SpreadTable } from 'SpreadTable';

const baseSlug = 'home';

const IndexPage: NextPage<IPage> = ({ title, meta }) => {
	const [requestData, setRequestData] = useState<IRequestData>(
		{} as IRequestData
	);
	const [showType, setShowType] = useState<ShowType>('spread');
	const [spreadFilter, setSpreadFilter] = useState<ISpreadFilter>(
		{} as ISpreadFilter
	);
	const [spreadIndex, setSpreadIndex] = useState<ISpreadIndex>(
		{} as ISpreadIndex
	);
	const [instrumentsOnTop, setInstrumentsOnTop] = useState<boolean>(true);
	const [isCountrySelectOpen, setIsCountrySelectOpen] =
		useState<boolean>(false);
	const [randomBrokerIndex, setRandomBrokerIndex] = useState<number>(0);

	const { defaultSpreadFilter } = useFetchCompare(requestData);
	const { defaultSpreadIndex } = useFetchIndex();

	useEffect(() => {
		if (defaultSpreadFilter !== undefined) {
			setSpreadFilter(defaultSpreadFilter);
		}
	}, [defaultSpreadFilter]);

	useEffect(() => {
		setSpreadIndex(defaultSpreadIndex);
	}, [defaultSpreadIndex]);

	useEffect(() => {
		const random = Math.floor(
			Math.random() *
				(spreadFilter && spreadFilter.filters
					? spreadFilter.filters.brokers.length
					: 0)
		);
		setRandomBrokerIndex(random);
	}, [spreadFilter]);

	const addCountry = (value: void | any) => {
		const requestData: IRequestData = {
			markets: [],
			countries: [value],
			brokers: [],
		};
		spreadFilter.filters.markets.map((row) => {
			requestData.markets.push(row.id);
		});
		saveFilterCookie(requestData);
		setRequestData(requestData);
		setIsCountrySelectOpen(false);
	};

	return (
		<AnimatePage>
			<SeoHead
				title={meta ? meta.title : ''}
				description={meta ? meta.description : ''}
				keywords={meta ? meta.keywords : ''}
				canonical="/"
			/>
			<Container>
				<h1 className="headline mt-12 text-3xl md:text-5xl lg:text-6xl text-center md:text-left">
					{title}
				</h1>
				<>
					{spreadFilter === undefined ||
					spreadIndex === undefined ||
					spreadFilter.filters === undefined ||
					spreadFilter.filters.countries === undefined ||
					spreadIndex.brokers === undefined ? (
						<PlaceholderIndex />
					) : (
						<>
							<div className="lg:flex justify-between my-8">
								<div className="text-lg py-2 mb-4 lg:mb-0 text-center md:text-left">
									Browsing the most popular brokers in{' '}
									{theCountries.includes(
										spreadFilter.filters.countries[0].id
									) && 'the '}
									<div className="whitespace-nowrap hidden">
										<span className="font-bold">
											{spreadFilter.filters.countries[0].name}
										</span>
										.
									</div>
									<div className="whitespace-nowrap inline-block ml-1">
										{!isCountrySelectOpen ? (
											<button
												onClick={() => setIsCountrySelectOpen(true)}
												type="button"
												className="opacity-80 hover:opacity-100 flex text-sm items-center rounded-xl dark:bg-gray-800 focus:outline-none
		focus:ring-0 bg-gray-200 px-4 py-1 dark:text-white group font-medium mt-3 md:mt-0"
											>
												<FontAwesomeIcon
													className="-ml-1 mr-1 h-3 w-3 dark:text-white group-hover:animate-pulse"
													icon={faLocationDot}
												/>

												<span className="subpixel-antialiased">
													{spreadFilter.filters.countries[0].name}
												</span>

												<span className="font-normal ml-2">
													<FontAwesomeIcon className="h-3 w-3" icon={faClose} />
												</span>
											</button>
										) : (
											<div className="relative text-base w-64 mt-3 md:mt-0">
												<div className="absolute top-0 bottom-0 left-0 flex items-center px-3 -mt-2 z-30">
													<div className="h-3 w-3 py-1 text-gray-600">
														<FontAwesomeIcon icon={faLocationDot} />
													</div>
												</div>
												<Select
													classNamePrefix="react-select"
													onChange={(el) => addCountry(el?.value)}
													className="country-select-container"
													placeholder="Select Country"
													options={mapCountryOptions(spreadIndex.countries)}
													noOptionsMessage={() => 'Not found...'}
													styles={{
														option: (base, state) => ({
															...base,
															background: state.isFocused
																? 'rgb(31 41 55)'
																: 'rgb(17 24 39)',
														}),
													}}
												/>
											</div>
										)}
									</div>
								</div>

								<ul className="nav nav-pills flex flex-row list-none pl-0 justify-center md:justify-start lg:justify-end items-center">
									{dataTypes.map((type) => {
										return (
											<ShowTypeItem
												key={type.id}
												id={type.id}
												name={type.name}
												activeId={showType}
												onClick={() => setShowType(type.id as typeof showType)}
											/>
										);
									})}
								</ul>
							</div>

							<SpreadTable
								spreadFilter={spreadFilter}
								spreadIndex={spreadIndex}
								baseSlug={baseSlug}
								showType={showType}
								instrumentsOnTop={instrumentsOnTop}
								setInstrumentsOnTop={setInstrumentsOnTop}
								setRequestData={setRequestData}
								setSpreadFilter={setSpreadFilter}
							/>
							<VersusList
								allBrokers={spreadIndex.brokers}
								filteredBrokers={spreadFilter.filters.brokers}
								randomBrokerIndex={randomBrokerIndex}
								baseSlug={baseSlug}
							/>
						</>
					)}
				</>
			</Container>
		</AnimatePage>
	);
};

export async function getStaticProps() {
	const response = await fetch(NEXT_PUBLIC_API_URL + `pages/${baseSlug}`);
	const res = await response.json();
	return {
		props: {
			...(res as IPage),
		},
		revalidate: 12 * 60 * 60,
	};
}
/*
export async function getStaticProps() {
	const response = await fetch(process.env.NEXT_PUBLIC_API_URL + 'compare', {
		method: 'post',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ attachIndex: 1 }),
	});
	const res = await response.json();
	return {
		props: {
			data: res,
			defaultShowType: 'spread',
		},
		revalidate: 30,
	};
}
*/
export default IndexPage;
